* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'NotoSans', sans-serif;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

.active-button {
  padding-bottom: 5px;
  border-bottom: 3px solid #29aabc;
  /* border-bottom: 2px solid transparent; */
  opacity: 1;
}

@font-face {
  font-family: 'Neutral Face';
  src: local('Neutral Face'), url(./fonts/NeutralFace.otf) format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  src: local('Noto Sans'),
    url(./fonts/NotoSans/NotoSans-Regular.ttf) format('truetype');
}
