.bannerImage {
  background: radial-gradient(#000000ed, #000000c7);
  padding: 70px 0;
  margin-bottom: -5px;
  width: 100vw;
  height: 70vh;
  /* object-fit: contain; */
}

.flexRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.image {
  padding: 70px;
  width: 100%;
  justify-self: center;
}

@media screen and (max-width: 768px) {
  .bannerImage {
    height: 45vh;
  }
  .flexRow {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
  }
  .image {
    padding: 0;
  }
}
