.box {
  margin-top: 40px;
  /* margin-bottom: -30px; */
}
@media screen and (min-width: 768px) {
  .box {
    display: none;
  }
}
.Dropdown-control {
  background-color: pink;
  color: #333;
  font-weight: bold;
  padding: 10px 0 10px 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.Dropdown-arrow-wrapper {
  padding-right: 10px;
}

.Dropdown-option {
  background-color: rebeccapurple;
  padding: 8px 10px;
}
.is-selected {
  background-color: pink;
}
