.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #03e9f4;
  font-size: 12px;
}

@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css');

.audun_warn {
  color: #000000;
  background-color: #ff8080;
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 0.5em;
  border: 1px solid;
  margin: 10px 0px;
  padding: 12px;
  width: auto;
  display: flex;
  gap: 20px;
  align-items: center;
}

.audun_info {
  color: #000000;
  background-color: #79cfe0;
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 0.5em;
  border: 1px solid;
  margin: 10px 0px;
  padding: 12px;
  width: auto;
  display: flex;
  gap: 20px;
  align-items: center;
}

.audun_success {
  color: #000000;
  background-color: #7ddf7c;
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 0.5em;
  border: 1px solid;
  margin: 10px 0px;
  padding: 12px;
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
