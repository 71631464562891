@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'NotoSans', sans-serif;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 0.75rem;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: 0.75rem;
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

.active-button {
  padding-bottom: 5px;
  border-bottom: 3px solid #29aabc;
  /* border-bottom: 2px solid transparent; */
  opacity: 1;
}

@font-face {
  font-family: 'Neutral Face';
  src: local('Neutral Face'), url(/static/media/NeutralFace.f68b9532.otf) format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  src: local('Noto Sans'),
    url(/static/media/NotoSans-Regular.ac08e269.ttf) format('truetype');
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #03e9f4;
  font-size: 12px;
}

.audun_warn {
  color: #000000;
  background-color: #ff8080;
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 0.5em;
  border: 1px solid;
  margin: 10px 0px;
  padding: 12px;
  width: auto;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}

.audun_info {
  color: #000000;
  background-color: #79cfe0;
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 0.5em;
  border: 1px solid;
  margin: 10px 0px;
  padding: 12px;
  width: auto;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}

.audun_success {
  color: #000000;
  background-color: #7ddf7c;
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 0.5em;
  border: 1px solid;
  margin: 10px 0px;
  padding: 12px;
  width: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}

.bannerImage {
  background: radial-gradient(#000000ed, #000000c7);
  padding: 70px 0;
  margin-bottom: -5px;
  width: 100vw;
  height: 70vh;
  /* object-fit: contain; */
}

.flexRow {
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
  gap: 20px;
}

.image {
  padding: 70px;
  width: 100%;
  justify-self: center;
}

@media screen and (max-width: 768px) {
  .bannerImage {
    height: 45vh;
  }
  .flexRow {
    display: flex;
    flex-direction: column-reverse;
    grid-gap: 20px;
    gap: 20px;
  }
  .image {
    padding: 0;
  }
}

.box {
  margin-top: 40px;
  /* margin-bottom: -30px; */
}
@media screen and (min-width: 768px) {
  .box {
    display: none;
  }
}
.Dropdown-control {
  background-color: pink;
  color: #333;
  font-weight: bold;
  padding: 10px 0 10px 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.Dropdown-arrow-wrapper {
  padding-right: 10px;
}

.Dropdown-option {
  background-color: rebeccapurple;
  padding: 8px 10px;
}
.is-selected {
  background-color: pink;
}

